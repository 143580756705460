import React, { FC } from 'react';
import { useInsights } from '../../context/insights';
import {
  CourseInstanceLeadTime,
  CourseInstanceLeadTimeData,
} from '../types/CourseInstanceLeadTime';
import { CourseInstanceLegend } from './CourseInstanceLegend';

type CourseInstanceLeadTimeLegendProps = {
  data?: CourseInstanceLeadTimeData;
};

export const CourseInstanceLeadTimeLegend: FC<
  CourseInstanceLeadTimeLegendProps
> = ({ data }) => {
  const { selectedLeadTime, setSelectedLeadTime } = useInsights();

  return (
    <CourseInstanceLegend
      data={data}
      isSelected={(id) => selectedLeadTime === id}
      isNotSelected={(id) => !!selectedLeadTime && selectedLeadTime !== id}
      onMouseEnter={(id) => setSelectedLeadTime(id as CourseInstanceLeadTime)}
      onMouseLeave={() => setSelectedLeadTime(null)}
    />
  );
};
