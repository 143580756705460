import { ArrowLeftOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import BookingOrderAPI from '../api/BookingOrderAPI';
import ClientCompanyField from '../components/ClientCompanyField';
import PurchaserField from '../components/PurchaserField';
import ContentCard from '../../components/ContentCard';
import CourseInstanceCard from '../../courseInstance/components/CourseInstanceCard';
import InvoiceRecipientPreview from '../components/InvoiceRecipientPreview';
import ResponsibleBookerPreview from '../components/ResponsibleBookerPreview';
import BookingOrder from '../types/BookingOrder';
import { AxiosError } from 'axios';
import BookingOrderNotFound from './BookingOrderNotFound';

const BookingOrderPreview: React.FC = () => {
  const { bookingOrderId } = useParams();
  const id = useMemo(() => Number(bookingOrderId), [bookingOrderId]);
  const [loadingBookingOrder, setLoadingBookingOrder] =
    useState<boolean>(false);
  const [bookingOrder, setBookingOrder] = useState<BookingOrder>();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchBookingOrder = useCallback(async (id: number) => {
    setLoadingBookingOrder(true);
    try {
      const { data } = await BookingOrderAPI.getBookingOrder(id);
      setBookingOrder(data);
    } catch (err) {
      if ((err as AxiosError).response?.status !== 404) {
        throw err;
      }
    } finally {
      setLoadingBookingOrder(false);
    }
  }, []);

  useEffect(() => {
    fetchBookingOrder(id);
  }, [fetchBookingOrder, id]);

  return (
    <>
      <div className="sm:flex sm:flex-row items-center pb-2">
        <div
          onClick={() => navigate(-1)}
          className="hover:underline cursor-pointer text-blueGrayDark">
          <ArrowLeftOutlined style={{ fontSize: 11 }} />
          <span className="pl-1 pt-0.5 text-xs">{t('common.goBack')}</span>
        </div>
      </div>
      <ContentCard className="mt-1">
        {loadingBookingOrder ? (
          <Spin
            size="large"
            className="flex justify-center items-center h-40"
          />
        ) : bookingOrder ? (
          <div>
            <div className="flex justify-between pb-4 border-solid border-gray-200 border-0 border-b-2">
              <div>
                <ClientCompanyField
                  selectedClientCompany={bookingOrder.clientCompany}
                  bookingOrderStatus={bookingOrder.status}
                  invoicedAt={bookingOrder.invoicedAt}
                  limeObjectId={bookingOrder.limeObjectId}
                />
              </div>
            </div>
            <div className="my-4 sm:my-8 flex flex-col sm:flex-row gap-y-4">
              <div className="sm:basis-0 grow overflow-hidden">
                <PurchaserField
                  selectedPurchaser={bookingOrder.contactPerson}
                />
              </div>
              <div className="border-solid border-gray-200 border-0 border-r-2 h-32 mx-2 hidden sm:block" />
              <div className="sm:basis-0 grow overflow-hidden">
                <InvoiceRecipientPreview
                  invoiceRecipient={bookingOrder.invoiceRecipient}
                />
              </div>
              <div className="border-solid border-gray-200 border-0 border-r-2 h-32 mx-2 hidden sm:block" />
              <div className="sm:basis-0 grow overflow-hidden">
                <ResponsibleBookerPreview
                  responsibleBooker={bookingOrder.responsibleBooker}
                />
              </div>
            </div>
            <div className="border-solid border-gray-200 border-0 border-b-2 pb-4 flex flex-row gap-2 h-10">
              <div className="text-xl font-semibold">
                {t('common.courseInstances')}
              </div>
            </div>
            {bookingOrder.courseInstances &&
            bookingOrder.courseInstances?.length > 0 ? (
              <div className="w-full flex flex-col pb-4">
                {bookingOrder.courseInstances.map((courseInstance, index) => (
                  <CourseInstanceCard
                    key={`displayedCourseInstance_${index}}`}
                    displayedCourseInstance={courseInstance}
                    previewOnly
                  />
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center text-center">
                <div className="text-lg font-semibold pt-10 pb-4">
                  {t('views.BookingOrderPreview.noCourseInstances')}
                </div>
              </div>
            )}
          </div>
        ) : (
          <BookingOrderNotFound />
        )}
      </ContentCard>
    </>
  );
};

export default BookingOrderPreview;
