import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { useTranslation } from 'react-i18next';
import { Card, Row } from 'antd';
import { useInsights } from '../../context/insights';
import {
  CourseInstanceLeadTime,
  CourseInstanceLeadTimeData,
} from '../types/CourseInstanceLeadTime';

type CourseInstanceLeadTimeDonutProps = {
  data?: CourseInstanceLeadTimeData;
};

const CourseInstanceLeadTimeDonut: React.FC<
  CourseInstanceLeadTimeDonutProps
> = ({ data }) => {
  const { t } = useTranslation();
  const { selectedLeadTime, setSelectedLeadTime } = useInsights();

  if (data === undefined) {
    return null;
  }

  const pieData = data.graphs.map((g) => ({
    id: g.id,
    label: g.label,
    value: g.data.reduce((prev, curr) => prev + curr.y, 0),
    color: g.color,
  }));

  const formatter = Intl.NumberFormat('en', {
    notation: 'standard',
  });

  const total = data.graphs
    .map((g) => g.data.reduce((prev, curr) => prev + curr.y, 0))
    .reduce((prev, curr) => prev + curr, 0);

  return (
    <>
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 flex flex-col items-center">
        <span className="text-xs font-medium">
          {t('views.InsightsV2.allCourses')}
        </span>
        <span className="text-3xl font-semibold leading-10">
          {formatter.format(total)}
        </span>
      </div>

      <ResponsivePie
        data={pieData}
        margin={{ top: 20, right: 10, bottom: 20, left: 10 }}
        innerRadius={0.73}
        padAngle={4}
        cornerRadius={8}
        sortByValue={true}
        borderWidth={5}
        borderColor={{
          from: 'color',
        }}
        colors={pieData.map((d) => d.color)}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        activeOuterRadiusOffset={8}
        tooltip={({ datum }) => (
          <Card className="shadow" bodyStyle={{ padding: '12px' }}>
            <Row className="text-sm items-center gap-2">
              <div
                className="h-3 w-3 rounded-full"
                style={{ backgroundColor: datum.color }}
              />
              <span>{datum.label}</span>
              <span className="ml-auto font-semibold">
                {formatter.format(Number.parseInt(datum.formattedValue))}
              </span>
            </Row>
          </Card>
        )}
        activeId={selectedLeadTime}
        onActiveIdChange={(e) => {
          if (e) {
            setSelectedLeadTime(e as CourseInstanceLeadTime);
          } else {
            setSelectedLeadTime(null);
          }
        }}
      />
    </>
  );
};

export default CourseInstanceLeadTimeDonut;
