import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Edit from '../../resources/img/edit.svg';
import Remove from '../../resources/img/remove.svg';
import Swap from '../../resources/img/swap.svg';
import { BookingOrderStatus } from '../types/BookingOrder';
import ClientCompany from '../types/ClientCompany';
import FieldSelect from '../../components/FieldSelect';

import InformationCard from './InformationCard';
import BookingOrderStatusBadge from './BookingOrderStatusBadge';
import CreateClientCompanyModal from './CreateClientCompanyModal';
import EditClientCompanyModal from './EditClientCompanyModal';
import BookingOrderInvoicedBadge from './BookingOrderInvoicedBadge';
import BookingOrderFromLimeBadge from './BookingOrderFromLimeBadge';
import BookingOrderFromSelfServiceBadge from './BookingOrderFromSelfServiceBadge';
import { Button } from 'antd';
import { CheckOutlined, FileSyncOutlined } from '@ant-design/icons';
import { MapCompanyModal } from './MapCompanyModal';
import { useFeatureToggles } from '../../context/featureToggles';

type ClientCompanyFieldProps = {
  bookingOrderId?: number;
  selectedClientCompany?: ClientCompany;
  setSelectedClientCompany?: (clientCompany?: ClientCompany) => void;
  clientCompanies?: ClientCompany[];
  fetchClientCompanies?: () => void;
  bookingOrderStatus?: BookingOrderStatus;
  invoicedAt?: string;
  limeObjectId?: number | null;
  isSelfService?: boolean | null;
  setDeleteClientCompanyOnSave?: (clientCompany: number) => void;
  disableDelete?: boolean;
  loading?: boolean;
};

const ClientCompanyField: React.FC<ClientCompanyFieldProps> = ({
  bookingOrderId,
  selectedClientCompany,
  setSelectedClientCompany,
  clientCompanies,
  fetchClientCompanies,
  bookingOrderStatus,
  invoicedAt,
  limeObjectId,
  isSelfService,
  setDeleteClientCompanyOnSave,
  disableDelete,
  loading,
}) => {
  const [showCreateClientCompanyModal, setShowCreateClientCompanyModal] =
    useState<boolean>(false);
  const [showEditClientCompanyModal, setShowEditClientCompanyModal] =
    useState<boolean>(false);
  const [showMapCompanyModal, setShowMapCompanyModal] =
    useState<boolean>(false);
  const [fieldSelectDefaultOpen, setFieldSelectDefaultOpen] =
    useState<boolean>(false);

  const { t } = useTranslation();

  const { isElsaIntegrationEnabled } = useFeatureToggles();

  const actionPopoverElements = useMemo(
    () =>
      fetchClientCompanies && setSelectedClientCompany
        ? [
            {
              rowElement: (
                <>
                  <img src={Swap} className="pr-2" />
                  {t('common.switch')}
                </>
              ),
              action: () => {
                setFieldSelectDefaultOpen(true);
                fetchClientCompanies();
                setSelectedClientCompany(undefined);
              },
              hidden: !setSelectedClientCompany,
            },
            {
              rowElement: (
                <>
                  <img src={Edit} className="pr-2" />
                  {t('common.edit')}
                </>
              ),
              action: () => setShowEditClientCompanyModal(true),
            },
            {
              rowElement: (
                <>
                  <img src={Remove} className="pr-2" />
                  {t('common.remove')}
                </>
              ),
              action: () => {
                setFieldSelectDefaultOpen(false);
                fetchClientCompanies();
                setSelectedClientCompany(undefined);
              },
              hidden: !setSelectedClientCompany,
            },
          ]
        : undefined,
    [fetchClientCompanies, setSelectedClientCompany, t],
  );

  const MappingInformation = useCallback(
    (elsaClientCompanyId?: string) => {
      if (elsaClientCompanyId) {
        return (
          <div className="flex flex-row items-center gap-1 text-safeLifeSuccess">
            <CheckOutlined />
            <span className="text-md">
              {t('components.ClientCompanyField.mappedInElsa')}
            </span>
          </div>
        );
      } else {
        return (
          <Button
            type="text"
            onClick={() => setShowMapCompanyModal(true)}
            className="text-white bg-safeLifeMedium">
            <FileSyncOutlined />
            {t('components.ClientCompanyField.mapToElsa')}
          </Button>
        );
      }
    },
    [t],
  );

  const closeMapCompanyModal = useCallback(() => {
    setShowMapCompanyModal(false);
  }, []);

  return (
    <>
      <div className="pl-2 pb-1 font-medium text-blueGrayDark">
        {t('common.company')}
      </div>
      <div className="h-8">
        {selectedClientCompany ? (
          <div className="flex flex-row items-center space-x-2">
            <InformationCard actionPopoverElements={actionPopoverElements}>
              <div className="text-2xl font-semibold flex flex-row pr-4 h-6 leading-none">
                {selectedClientCompany.name}
              </div>
            </InformationCard>
            {isElsaIntegrationEnabled &&
              MappingInformation(selectedClientCompany.elsaClientCompanyId)}
            <BookingOrderStatusBadge status={bookingOrderStatus} />
            {invoicedAt && <BookingOrderInvoicedBadge />}
            {limeObjectId && <BookingOrderFromLimeBadge />}
            {isSelfService && <BookingOrderFromSelfServiceBadge />}
          </div>
        ) : (
          <FieldSelect
            defaultOpen={fieldSelectDefaultOpen}
            className="w-64 pl-2"
            placeholderText={t('components.ClientCompanyField.pickCompany')}
            createButtonText={t(
              'components.ClientCompanyField.createNewCompany',
            )}
            loading={loading}
            elements={clientCompanies?.map((clientCompany) => ({
              primaryText: clientCompany.name,
              id: clientCompany.id,
            }))}
            onCreate={() => setShowCreateClientCompanyModal(true)}
            onSelect={(selectedId) =>
              setSelectedClientCompany?.(
                clientCompanies?.find((client) => client.id === selectedId),
              )
            }
          />
        )}
      </div>
      <CreateClientCompanyModal
        visible={showCreateClientCompanyModal}
        onCancel={() => setShowCreateClientCompanyModal(false)}
        onCreate={(clientCompany) => {
          setSelectedClientCompany?.(clientCompany);
          setShowCreateClientCompanyModal(false);
        }}
      />
      {selectedClientCompany && (
        <EditClientCompanyModal
          bookingOrderId={bookingOrderId}
          visible={showEditClientCompanyModal}
          disableDelete={disableDelete}
          clientCompany={selectedClientCompany}
          onUpdate={(clientCompany) => {
            setSelectedClientCompany?.(clientCompany);
            setShowEditClientCompanyModal(false);
          }}
          onDelete={() => {
            setDeleteClientCompanyOnSave?.(selectedClientCompany.id);
            setSelectedClientCompany?.(undefined);
            setShowEditClientCompanyModal(false);
          }}
          onCancel={() => setShowEditClientCompanyModal(false)}
        />
      )}
      {showMapCompanyModal && selectedClientCompany && (
        <MapCompanyModal
          clientCompany={selectedClientCompany}
          close={closeMapCompanyModal}
          onUpdate={(cc: ClientCompany) => setSelectedClientCompany?.(cc)}
        />
      )}
    </>
  );
};

export default ClientCompanyField;
