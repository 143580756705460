import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useLocation,
  useParams,
  useOutletContext,
  useNavigate,
} from 'react-router-dom';
import {
  Modal,
  Form,
  Input,
  Button,
  Switch,
  Popconfirm,
  InputNumber,
  Space,
  Layout,
  Spin,
} from 'antd';
import { LinkOutlined, LockOutlined, WarningOutlined } from '@ant-design/icons';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { useTranslation } from 'react-i18next';

import Course from '../types/Course';
import CourseAPI, {
  UpdateCourseParams,
  UpdateCourseResourceParams,
} from '../CourseAPI';
import UserAPI from '../../user/UserAPI';
import { useUser } from '../../user/userContext';
import CourseResource from '../types/CourseResource';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';
import Certificate from '../../resources/img/certificate.svg';

import CourseResourceEditor from './CourseResourceEditor';
import CreateOrEditCertificateTemplateModal from './CreateOrEditCertificateTemplateModal';
import { CertificateTemplate } from '../../certificate/types/Certificate';
import CertificateAPI from '../../certificate/CertificateAPI';
import colors from '../../theme/colors';
import { UUID_PATTERN } from '../../utils/validation';
import { useFeatureToggles } from '../../context/featureToggles';

interface CourseFormValues {
  name: string;
  defaultPrice?: number;
  favourite?: boolean;
  courseResources: UpdateCourseResourceParams[];
  vismaInventoryNumber?: string;
  elsaCourseId?: string;
}

type CourseModalContext = {
  courses?: Course[];
  onClose?: () => void;
};

const CourseModal: React.FC = () => {
  const { courseId } = useParams();
  const id = useMemo(() => Number(courseId), [courseId]);
  const { courses, onClose } = useOutletContext<CourseModalContext>();
  const course = courses?.find((b) => b.id === id);

  const navigate = useNavigate();
  const location = useLocation();
  const [editCourseForm] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useUser();
  const [courseResources, setCourseResources] = useState<CourseResource[]>();
  const [deletedLinkIds, setDeletedLinkIds] = useState<number[]>([]);
  const [loadedCourseResources, setLoadedCourseResources] =
    useState<boolean>(false);
  const [createCertificateModalOpen, setCreateCertificateModalOpen] =
    useState(false);
  const { t } = useTranslation();
  const [certificateTemplate, setCertificateTemplate] = useState<
    CertificateTemplate | undefined
  >();
  const { isElsaIntegrationEnabled } = useFeatureToggles();

  const fetchCourseResources = useCallback(async () => {
    if (!course) return;

    try {
      const courseResourcesPromise = CourseAPI.getCourseResources(
        course.id,
      ).then(({ data }) => setCourseResources(data));

      const templateDataPromise = CertificateAPI.getCourseTemplate(
        course.id,
      ).then(({ data }) => setCertificateTemplate(data));

      await Promise.all([courseResourcesPromise, templateDataPromise]);
    } finally {
      setLoadedCourseResources(true);
      editCourseForm.resetFields();
    }
  }, [course, editCourseForm]);

  useEffect(() => {
    fetchCourseResources();
  }, [fetchCourseResources]);

  if (!course) return null;

  const close = () => {
    if (location.state?.fromCourses) {
      navigate(-1);
    } else {
      navigate('..', { replace: true });
    }
  };

  const deleteCourse = async () => {
    setDeleting(true);
    try {
      await CourseAPI.deleteCourse(course.id);
      onClose?.();
      close();
    } finally {
      setDeleting(false);
    }
  };

  const onFinish = async (values: CourseFormValues) => {
    setSubmitting(true);

    const updateCourseResourcesParams = values.courseResources.map(
      (resource) => {
        return {
          id: resource.id,
          title: resource.title,
          url: resource.url,
          delete: resource.delete,
        };
      },
    );

    courseResources?.forEach((courseResource) => {
      if (courseResource.id && deletedLinkIds.includes(courseResource.id)) {
        updateCourseResourcesParams.push({
          id: courseResource.id,
          title: courseResource.title,
          url: courseResource.url,
          delete: true,
        });
      }
    });

    try {
      if (
        !course.elsaCourseId &&
        !!values.elsaCourseId &&
        !confirm(t('common.elsaMappingLastWarning'))
      ) {
        setSubmitting(false);
        return;
      }

      await CourseAPI.updateCourse(course.id, {
        name: values.name,
        defaultPrice: values.defaultPrice,
        currencyId: course.currency.id,
        courseResources: updateCourseResourcesParams,
        vismaInventoryNumber: values.vismaInventoryNumber || null,
        elsaCourseId: values.elsaCourseId ?? undefined,
      } as UpdateCourseParams);

      if (values.favourite != null) {
        updateFavouriteCourses(values.favourite);
      }
    } finally {
      setSubmitting(false);
      onClose?.();
      close();
    }
  };

  const updateFavouriteCourses = async (isFavourite: boolean) => {
    if (currentUser && isFavourite) {
      UserAPI.setUserFavouriteCourses(
        currentUser?.id,
        currentUser.favouriteCourses
          .map((course) => course.id)
          .concat([course.id]),
      ).then(({ data }) => setCurrentUser(data));
    } else if (currentUser) {
      UserAPI.setUserFavouriteCourses(
        currentUser?.id,
        currentUser.favouriteCourses
          .map((course) => course.id)
          .filter((courseId) => courseId !== course.id),
      ).then(({ data }) => setCurrentUser(data));
    }
  };

  const onRemoveCourseResource = (field: FormListFieldData) => {
    if (courseResources && field.key < courseResources?.length) {
      setDeletedLinkIds((prevValues) =>
        prevValues?.concat(courseResources[field.key].id),
      );
    }
  };

  const onDeleteCertificate = async () => {
    if (certificateTemplate) {
      await CertificateAPI.deleteTemplate(certificateTemplate.id);
      setCertificateTemplate(undefined);
    }
  };

  const certificatelanguages = certificateTemplate
    ? certificateTemplate.languageTextContents
        .map((el) => el.certificateLanguage.name)
        .join(', ')
    : '-';

  return (
    <>
      <Modal
        key="course-modal"
        title={course.name}
        open
        onCancel={close}
        onOk={editCourseForm.submit}
        footer={
          <div className="flex justify-end">
            <Button key="back" onClick={close}>
              {t('common.close')}
            </Button>
            <div key="admin-button" className="pl-2">
              {userHasPermission(currentUser, Permission.COURSE_DELETE) && (
                <Popconfirm
                  key="delete"
                  title={
                    <div className="flex">
                      <p>{t('components.CourseModal.confirmDelete')}&nbsp;</p>
                      <em>{course.name}</em>?
                    </div>
                  }
                  okText={t('common.yes')}
                  cancelText={t('common.no')}
                  onConfirm={deleteCourse}
                  placement="bottom">
                  <Button type="primary" danger loading={deleting}>
                    {t('common.delete')}
                  </Button>
                </Popconfirm>
              )}
              {userHasPermission(currentUser, Permission.COURSE_UPDATE) && (
                <Button
                  key="submit"
                  type="primary"
                  loading={submitting}
                  form="editCourseForm"
                  htmlType="submit"
                  disabled={!loadedCourseResources}>
                  {t('common.save')}
                </Button>
              )}
            </div>
          </div>
        }
        maskClosable={false}>
        {userHasPermission(currentUser, Permission.COURSE_UPDATE) ? (
          loadedCourseResources ? (
            <Form
              id="editCourseForm"
              form={editCourseForm}
              onFinish={(values) => onFinish(values as CourseFormValues)}
              initialValues={{
                name: course.name,
                defaultPrice: course.defaultPrice,
                courseResources: courseResources,
                vismaInventoryNumber: course.vismaInventoryNumber,
                elsaCourseId: course.elsaCourseId,
              }}>
              <Form.Item label={t('common.name')} name="name">
                <Input />
              </Form.Item>
              <Form.Item label={t('components.CourseModal.defaultPrice')}>
                <Space>
                  <Form.Item name="defaultPrice" noStyle>
                    <InputNumber min={0} />
                  </Form.Item>
                  {course.currency.code}
                </Space>
              </Form.Item>
              <Form.Item
                label={t('components.CourseModal.favourite')}
                name="favourite"
                valuePropName="checked">
                <Switch
                  defaultChecked={currentUser?.favouriteCourses
                    .map((favCourses) =>
                      favCourses.id === course.id ? true : false,
                    )
                    .includes(true)}
                />
              </Form.Item>
              <div className="text-base font-medium border-solid border-gray-200 border-0 border-t-2 pt-3 pb-3">
                {t('components.CourseModal.courseInformation')}
              </div>
              <CourseResourceEditor
                loading={!loadedCourseResources}
                onRemove={(field) => {
                  onRemoveCourseResource(field);
                }}
              />
              <div className="text-base font-medium border-solid border-gray-200 border-0 border-t-2 mt-6 py-3">
                {t('components.CourseModal.vismaId')}
              </div>
              <Form.Item name="vismaInventoryNumber">
                <Input placeholder={t('components.CourseModal.vismaId')} />
              </Form.Item>
              {isElsaIntegrationEnabled && (
                <>
                  <div className="text-base font-medium border-solid border-gray-200 border-0 border-t-2 mt-6 py-3">
                    {t('common.elsaMapping')}
                  </div>
                  <Form.Item
                    name="elsaCourseId"
                    rules={[
                      {
                        pattern: UUID_PATTERN,
                        message: t('common.mustBeValidUUID'),
                      },
                    ]}>
                    <Input
                      placeholder={t('common.enterElsaUUID')}
                      disabled={!!course.elsaCourseId}
                      {...(!!course.elsaCourseId && {
                        suffix: <LockOutlined />,
                      })}
                    />
                  </Form.Item>
                  {!course.elsaCourseId && (
                    <div className="flex flex-row gap-4 items-center mb-2">
                      <WarningOutlined
                        style={{
                          fontSize: '24px',
                          color: colors.warningYellow,
                        }}
                      />
                      <span>{t('common.elsaMappingWarning')}</span>
                    </div>
                  )}
                </>
              )}
              <div className="border-dashed border-gray-200 border-0 border-y-2 pt-4 pb-1 mb-6">
                <div className="text-base font-medium pb-2">
                  {t('components.CourseModal.certificateForSubsidiaryCompany', {
                    companyName: currentUser?.preferredSubsidiaryCompany?.name,
                  })}
                </div>

                <div className="flex flex-row justify-between pb-6">
                  <div className="flex items-center">
                    <img src={Certificate} className="pr-2" />
                    <div>{certificatelanguages}</div>
                  </div>
                  {certificateTemplate ? (
                    <Space>
                      <Popconfirm
                        title={
                          <p>
                            {t(
                              'components.CourseModal.confirmDeleteCertificate',
                            )}
                          </p>
                        }
                        okText={t('common.yes')}
                        cancelText={t('common.no')}
                        onConfirm={onDeleteCertificate}
                        placement="bottom">
                        <Button danger type="text">
                          {t('common.delete')}
                        </Button>
                      </Popconfirm>
                      <Button
                        className="bg-gray-200 text-gray-900"
                        onClick={() => setCreateCertificateModalOpen(true)}>
                        {t('common.edit')}
                      </Button>
                    </Space>
                  ) : (
                    <Button
                      type="primary"
                      className="bg-gray-200 text-gray-900"
                      onClick={() => setCreateCertificateModalOpen(true)}>
                      {t('components.CourseModal.createCertificate')}
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          ) : (
            <Layout.Content className="flex justify-center items-center py-5">
              <Spin size="large" />
            </Layout.Content>
          )
        ) : (
          <>
            <div className="text-base font-medium pb-3">
              {t('components.CourseModal.courseInformation')}
            </div>
            {courseResources && courseResources.length > 0 ? (
              <div className="flex flex-col">
                {courseResources?.map((courseResource) => (
                  <Button
                    key={courseResource.id}
                    className="my-2"
                    href={courseResource.url}
                    target="_blank"
                    rel="noreferrer">
                    <div className="flex flex-start items-center text-md">
                      {courseResource.title}
                      <LinkOutlined className="pl-1 text-gray-500" />
                    </div>
                  </Button>
                ))}
              </div>
            ) : (
              <div className="text-gray-500">
                {t('components.CourseModal.noCourseInformation')}
              </div>
            )}
          </>
        )}
      </Modal>
      {loadedCourseResources && createCertificateModalOpen && (
        <CreateOrEditCertificateTemplateModal
          course={course}
          isOpen={createCertificateModalOpen}
          onClose={() => setCreateCertificateModalOpen(false)}
          onTemplateUpdated={setCertificateTemplate}
          certificateTemplate={certificateTemplate}
        />
      )}
    </>
  );
};

export default CourseModal;
