import React, { useState, useEffect, useCallback } from 'react';
import { Input, Form, InputNumber, Tooltip, Space } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';

import ContactPersonAPI from '../../bookingOrders/api/ContactPersonAPI';
import AddressSearchInput, {
  PlaceSuggestion,
} from '../../components/AddressSearchInput';
import DetailsRow from '../../components/DetailsRow';
import { useUser } from '../../user/userContext';
import AddressDetailsRow from '../../components/AddressDetailsRow';
import { DisplayedCourseInstance } from '../types/CourseInstance';
import * as DateUtils from '../../utils/date';
import ContactPerson from '../../bookingOrders/types/ContactPerson';
import ClientCompany from '../../bookingOrders/types/ClientCompany';
import { MinimalUser } from '../../user/types/User';
import Course from '../../course/types/Course';
import BookingOrderInformation from '../../bookingOrders/components/BookingOrderInformation';
import FieldSelect from '../../components/FieldSelect';
import CreateContactPersonModal from '../../bookingOrders/components/CreateContactPersonModal';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';
import { getFullContactName } from '../../bookingOrders/utils/contactPerson';

import CourseInstanceHeader from './CourseInstanceHeader';

export type CourseInstanceUpdateValues = {
  clientAddress?: PlaceSuggestion;
  clientCorporateId?: string;
  invoiceAddress?: string;
  contactPerson?: ContactPerson | null;
  subsidiaryCompanyId?: number;
  price?: number;
  equipment?: string;
  location?: PlaceSuggestion;
  address?: string;
  comment?: string;
  date?: dayjs.Dayjs;
  time?: [dayjs.Dayjs, dayjs.Dayjs];
  course?: Course;
  instructor?: MinimalUser;
  mileagePrice?: number;
  mileageQuantity?: number;
  participantLimit?: number;
};

type EditCourseInstanceProps = {
  displayedCourseInstance: DisplayedCourseInstance;
  bookingOrderContactPerson?: ContactPerson;
  bookingOrderClientCompany?: ClientCompany;
  onCourseInstanceUpdate?: (
    displayedCourseInstance: DisplayedCourseInstance,
  ) => void;
};

dayjs.extend(relativeTime);

const EditCourseInstance: React.FC<EditCourseInstanceProps> = ({
  displayedCourseInstance,
  bookingOrderContactPerson,
  bookingOrderClientCompany,
  onCourseInstanceUpdate,
}) => {
  const [currentUser] = useUser();
  const [enteredPrice, setEnteredPrice] = useState<number | null>();
  const [enteredParticipantLimit, setEnteredParticipantLimit] = useState<
    number | null
  >();
  const { t } = useTranslation();

  const [showCreateContactPersonModal, setShowCreateContactPersonModal] =
    useState<boolean>(false);

  const fetchContactPersons = useCallback(async () => {
    const { data } = await ContactPersonAPI.getContactPersons();
    setContactPersons(data);
  }, []);

  useEffect(() => {
    fetchContactPersons();
  }, [fetchContactPersons]);

  const [contactPersons, setContactPersons] = useState<ContactPerson[]>();

  const onFinish = useCallback(
    async (values: CourseInstanceUpdateValues, isCriticalUpdate?: boolean) => {
      if (!displayedCourseInstance) {
        return;
      }

      const newStartsAt =
        !!values.time && isCriticalUpdate
          ? DateUtils.mergeDateTime(
              dayjs(values.date),
              values.time[0],
            ).toISOString()
          : null;

      const newEndsAt =
        !!values.time && isCriticalUpdate
          ? DateUtils.mergeDateTime(
              dayjs(values.date),
              values.time[1],
            ).toISOString()
          : null;

      const locationChanged = values.location !== undefined;
      const contactPersonChanged = values.contactPerson !== undefined;

      onCourseInstanceUpdate?.({
        id: displayedCourseInstance.id,
        course: isCriticalUpdate
          ? values.course
          : displayedCourseInstance.course,
        orderId: displayedCourseInstance.orderId,
        createdBy: displayedCourseInstance.createdBy,
        startsAt: isCriticalUpdate
          ? newStartsAt
          : displayedCourseInstance.startsAt,
        endsAt: isCriticalUpdate ? newEndsAt : displayedCourseInstance.endsAt,
        contactPerson: contactPersonChanged
          ? values.contactPerson
          : displayedCourseInstance.contactPerson,
        instructor: isCriticalUpdate
          ? values.instructor
          : displayedCourseInstance.instructor,
        price: values.price ?? displayedCourseInstance.price,
        currency: displayedCourseInstance.currency,
        address: locationChanged
          ? values.location?.address
          : displayedCourseInstance.address,
        placeId: locationChanged
          ? values.location?.placeId
          : displayedCourseInstance.placeId,
        lat: locationChanged
          ? values.location?.geolocation.lat
          : displayedCourseInstance.lat,
        lng: locationChanged
          ? values.location?.geolocation.lng
          : displayedCourseInstance.lng,
        comment: values.comment ?? displayedCourseInstance.comment,
        equipment: values.equipment ?? displayedCourseInstance.equipment,
        mileagePrice:
          values.mileagePrice ?? displayedCourseInstance.mileagePrice,
        mileageQuantity:
          values.mileageQuantity ?? displayedCourseInstance.mileageQuantity,
        participantLimit:
          values.participantLimit ?? displayedCourseInstance.participantLimit,
      } as DisplayedCourseInstance);
    },
    [displayedCourseInstance, onCourseInstanceUpdate],
  );

  const onCreateContact = useCallback(
    (contactPerson: ContactPerson) => {
      setShowCreateContactPersonModal(false);
      setContactPersons((prevState) => prevState?.concat(contactPerson));
      onFinish({ contactPerson: contactPerson });
    },
    [onFinish],
  );

  const hideCreateContactModal = useCallback(() => {
    setShowCreateContactPersonModal(false);
  }, []);

  const onEditFinish = useCallback(
    (values: CourseInstanceUpdateValues) => onFinish(values, true),
    [onFinish],
  );

  return (
    <>
      <CreateContactPersonModal
        visible={showCreateContactPersonModal}
        onCreate={onCreateContact}
        onCancel={hideCreateContactModal}
      />
      {(bookingOrderClientCompany || bookingOrderContactPerson) && (
        <div className="-mx-6">
          <BookingOrderInformation
            className="my-5"
            contactPerson={bookingOrderContactPerson}
            clientCompany={bookingOrderClientCompany}
          />
        </div>
      )}
      <CourseInstanceHeader
        displayedCourseInstance={displayedCourseInstance}
        editable={userHasPermission(
          currentUser,
          Permission.COURSE_INSTANCE_UPDATE,
        )}
        onFinish={onEditFinish}
      />
      <DetailsRow
        label={t('common.note')}
        displayValue={
          <>
            {displayedCourseInstance?.comment ? (
              <span style={{ whiteSpace: 'pre-line' }}>
                {displayedCourseInstance?.comment}
              </span>
            ) : (
              <span className="text-sm text-gray-600">
                {userHasPermission(
                  currentUser,
                  Permission.COURSE_INSTANCE_UPDATE,
                )
                  ? t('components.EditCourseInstance.addComment')
                  : ''}
              </span>
            )}
          </>
        }
        editable={userHasPermission(
          currentUser,
          Permission.COURSE_INSTANCE_UPDATE,
        )}
        formContent={
          <Form.Item
            name="comment"
            initialValue={displayedCourseInstance?.comment}>
            <Input.TextArea
              placeholder={t('components.EditCourseInstance.comments')}
              autoSize={{ minRows: 5, maxRows: 20 }}
            />
          </Form.Item>
        }
        onFinish={(values) => onFinish(values as CourseInstanceUpdateValues)}
      />
      <DetailsRow
        // Update key of component to reset editing state with a re-render
        key={displayedCourseInstance.contactPerson?.id}
        label={t('components.EditCourseInstance.contactPerson')}
        displayValue={
          <div className="flex flex-col">
            {displayedCourseInstance.contactPerson && (
              <span>
                {getFullContactName(displayedCourseInstance.contactPerson)}
              </span>
            )}
            {userHasPermission(
              currentUser,
              Permission.COURSE_INSTANCE_READ_FULL,
            ) && <span>{displayedCourseInstance?.contactPerson?.email}</span>}
            <span>{displayedCourseInstance?.contactPerson?.phoneNumber}</span>
          </div>
        }
        editable={userHasPermission(
          currentUser,
          Permission.COURSE_INSTANCE_UPDATE,
        )}
        formContent={
          <FieldSelect
            className="mb-6"
            formName="contactPersonId"
            placeholderText={t(
              'components.EditCourseInstance.selectContactPerson',
            )}
            elements={contactPersons?.map((contactPerson) => ({
              primaryText: getFullContactName(contactPerson),
              secondaryText: contactPerson.email,
              id: contactPerson.id,
            }))}
            initialValue={displayedCourseInstance.contactPerson?.id}
            createButtonText={t(
              'components.EditCourseInstance.createNewContactPerson',
            )}
            onCreate={() => setShowCreateContactPersonModal(true)}
          />
        }
        onFinish={(values) =>
          onFinish({
            contactPerson:
              contactPersons?.find(
                (contactPerson) => contactPerson.id === values.contactPersonId,
              ) ?? null,
          } as CourseInstanceUpdateValues)
        }
      />
      <AddressDetailsRow
        label={t('common.location')}
        displayValue={displayedCourseInstance?.address}
        editable={userHasPermission(
          currentUser,
          Permission.COURSE_INSTANCE_UPDATE,
        )}
        formContent={
          <>
            <Form.Item name="location">
              <AddressSearchInput
                initialValue={displayedCourseInstance?.address}
                placeholder={t(
                  'components.EditCourseInstance.addressForInstance',
                )}
              />
            </Form.Item>
          </>
        }
        onFinish={(values) => onFinish(values as CourseInstanceUpdateValues)}
      />
      <DetailsRow
        label={t('components.EditCourseInstance.equipment')}
        displayValue={
          <>
            {displayedCourseInstance?.equipment ? (
              <span>{displayedCourseInstance?.equipment}</span>
            ) : (
              <span className="text-sm text-gray-600">
                {userHasPermission(
                  currentUser,
                  Permission.COURSE_INSTANCE_UPDATE,
                )
                  ? t('components.EditCourseInstance.addEquipment')
                  : ''}
              </span>
            )}
          </>
        }
        editable={userHasPermission(
          currentUser,
          Permission.COURSE_INSTANCE_UPDATE,
        )}
        formContent={
          <Form.Item
            name="equipment"
            initialValue={displayedCourseInstance?.equipment}>
            <Input.TextArea
              placeholder={t('components.EditCourseInstance.equipment')}
            />
          </Form.Item>
        }
        onFinish={(values) => onFinish(values as CourseInstanceUpdateValues)}
      />

      {userHasPermission(currentUser, Permission.COURSE_INSTANCE_READ_FULL) && (
        <>
          <DetailsRow
            label={t('common.price')}
            displayValue={
              displayedCourseInstance?.price !== undefined
                ? `${displayedCourseInstance?.price} ${displayedCourseInstance?.currency.code}`
                : ''
            }
            editable={userHasPermission(
              currentUser,
              Permission.COURSE_INSTANCE_UPDATE,
            )}
            formContent={
              <Form.Item
                name="price"
                initialValue={displayedCourseInstance?.price}>
                <Space>
                  <InputNumber
                    placeholder={t('common.price')}
                    min={0}
                    onChange={setEnteredPrice}
                  />
                  {displayedCourseInstance.currency.code}
                </Space>
              </Form.Item>
            }
            disableSave={enteredPrice === null}
            onFinish={(values) =>
              onFinish(values as CourseInstanceUpdateValues)
            }
          />
          <DetailsRow
            label={t('common.mileage')}
            displayValue={
              <div>
                <div>
                  {t('components.EditCourseInstance.mileageQuantity')}:{' '}
                  {displayedCourseInstance?.mileageQuantity !== undefined
                    ? `${displayedCourseInstance?.mileageQuantity}`
                    : ''}
                </div>
                <div>
                  {t('components.EditCourseInstance.mileagePrice')}:{' '}
                  {displayedCourseInstance?.mileagePrice !== undefined
                    ? `${displayedCourseInstance?.mileagePrice} ${displayedCourseInstance?.currency.code}`
                    : ''}
                </div>
              </div>
            }
            editable={userHasPermission(
              currentUser,
              Permission.COURSE_INSTANCE_UPDATE,
            )}
            formContent={
              <div className="flex">
                <Form.Item
                  label={t('components.EditCourseInstance.mileageQuantity')}
                  className="mr-8"
                  name="mileageQuantity"
                  initialValue={displayedCourseInstance?.mileageQuantity}>
                  <InputNumber
                    placeholder={t(
                      'components.EditCourseInstance.mileageQuantity',
                    )}
                    min={0}
                  />
                </Form.Item>
                <Form.Item
                  label={t('components.EditCourseInstance.mileagePrice')}
                  name="mileagePrice"
                  initialValue={displayedCourseInstance?.mileagePrice}>
                  <InputNumber
                    placeholder={t(
                      'components.EditCourseInstance.mileagePrice',
                    )}
                    min={0}
                    max={99}
                  />
                </Form.Item>
              </div>
            }
            onFinish={(values) =>
              onFinish(values as CourseInstanceUpdateValues)
            }
          />
          {displayedCourseInstance.elsaTrainingSessionId && (
            <DetailsRow
              label={t('components.EditCourseInstance.participantLimit')}
              displayValue={
                <div>
                  {displayedCourseInstance?.participantLimit !== undefined &&
                  displayedCourseInstance.participantLimit !== null
                    ? `${displayedCourseInstance?.participantLimit}`
                    : 'n/a'}
                </div>
              }
              editable={userHasPermission(
                currentUser,
                Permission.COURSE_INSTANCE_UPDATE,
              )}
              formContent={
                <Form.Item
                  name="participantLimit"
                  initialValue={displayedCourseInstance?.participantLimit}>
                  <InputNumber
                    placeholder={t(
                      'components.EditCourseInstance.participantLimit',
                    )}
                    min={0}
                    onChange={setEnteredParticipantLimit}
                    style={{ width: 'auto' }}
                  />
                </Form.Item>
              }
              disableSave={enteredParticipantLimit === null}
              onFinish={(values) =>
                onFinish(values as CourseInstanceUpdateValues)
              }
            />
          )}
        </>
      )}
      {displayedCourseInstance?.createdBy && (
        <DetailsRow
          label={t('common.createdBy')}
          displayValue={
            currentUser?.isInstructor ? (
              <div>
                <div>{displayedCourseInstance.createdBy.name}</div>
                <div>{displayedCourseInstance.createdBy.email}</div>
                <div>{displayedCourseInstance.createdBy.phoneNumber}</div>
              </div>
            ) : (
              displayedCourseInstance.createdBy.name
            )
          }
        />
      )}
      <DetailsRow
        label={t('common.created')}
        displayValue={
          <Tooltip
            placement="top"
            title={dayjs(displayedCourseInstance?.createdAt).format(
              'DD/MM-YYYY HH:mm',
            )}>
            {dayjs(displayedCourseInstance?.createdAt).fromNow()}
          </Tooltip>
        }
      />
      <DetailsRow
        label={t('components.EditCourseInstance.lastEdited')}
        displayValue={
          <Tooltip
            placement="top"
            title={dayjs(displayedCourseInstance?.updatedAt).format(
              'DD/MM-YYYY HH:mm',
            )}>
            {dayjs(displayedCourseInstance?.updatedAt).fromNow()}
          </Tooltip>
        }
      />
    </>
  );
};

export default EditCourseInstance;
