import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { useInsights } from '../../context/insights';
import { linearGradientDef } from '@nivo/core';
import { Card, Row } from 'antd';
import {
  translateCourseInstanceLeadTime,
  translateCourseInstanceLeadTimeXMode,
  translateCourseInstanceLeadTimeYMode,
} from '../utils/translate';
import {
  CourseInstanceLeadTime,
  CourseInstanceLeadTimeData,
} from '../types/CourseInstanceLeadTime';
import NoCourseInstances from './NoCourseInstances';
import dayjs from 'dayjs';
import {
  computeTickValues,
  getDateRangeFormat,
  renderLightTick,
} from '../utils/axis';
import { bucketGraphData } from '../utils/bucketSize';

type CourseInstanceLeadTimeGraphProps = {
  data?: CourseInstanceLeadTimeData;
  displayedStart?: dayjs.Dayjs;
  displayedEnd?: dayjs.Dayjs;
};

const CourseInstanceLeadTimeGraph: React.FC<
  CourseInstanceLeadTimeGraphProps
> = ({ data, displayedStart, displayedEnd }) => {
  const {
    endDate,
    leadTimeXMode: xMode,
    leadTimeYMode: yMode,
    leadTimeBucketSize,
    selectedLeadTime,
  } = useInsights();

  if (
    data === undefined ||
    displayedStart === undefined ||
    displayedEnd === undefined
  ) {
    return <NoCourseInstances />;
  }

  const bucketedData = bucketGraphData(data.graphs, leadTimeBucketSize);
  const tickValues = computeTickValues(bucketedData[0].data.map((d) => d.x));
  const dateRangeFormat = getDateRangeFormat(displayedStart, displayedEnd);

  return (
    <ResponsiveLine
      data={bucketedData}
      margin={{ top: 10, right: 40, bottom: 50, left: 56 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 0,
        max: 'auto',
      }}
      curve="linear"
      lineWidth={2.5}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        tickValues,
        legend: translateCourseInstanceLeadTimeXMode(xMode),
        legendOffset: 36,
        legendPosition: 'middle',
        truncateTickAt: 0,
        format: (v: Date) => dayjs(v).format('D MMM'),
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: translateCourseInstanceLeadTimeYMode(yMode),
        legendOffset: -48,
        legendPosition: 'middle',
        truncateTickAt: 0,
        renderTick: (props) =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderLightTick(props, (value: any) => {
            if (value % 1 === 0) {
              return value;
            }
          }),
      }}
      enableArea={true}
      enableGridX={false}
      enablePoints={false}
      enableSlices="x"
      enableCrosshair={true}
      enableTouchCrosshair={true}
      useMesh={true}
      colors={data.graphs.map((g) => {
        const color = g.color;

        // If a line is selected we will decrease the colors of the other lines
        if (selectedLeadTime && g.id !== selectedLeadTime) {
          return `${color.substring(0, 7)}22`;
        }

        return color;
      })}
      defs={[
        linearGradientDef('transparent', [
          { offset: 0, color: 'inherit', opacity: 0 },
        ]),
      ]}
      fill={[{ match: '*', id: 'transparent' }]}
      sliceTooltip={({ slice: { points } }) => {
        const bucketStartDate = dayjs(points[0].data.xFormatted);
        const bucketEndDate = dayjs(points[0].data.xFormatted).add(
          leadTimeBucketSize - 1,
          'day',
        );
        const bucketEndDateCeiled = bucketEndDate.isAfter(endDate)
          ? endDate
          : bucketEndDate;

        return (
          <Card className="shadow" bodyStyle={{ padding: '12px' }}>
            {points.toReversed().map((p) => (
              <Row key={p.id} className="text-sm items-center gap-2">
                <div
                  className="h-1 w-4 rounded-full"
                  style={{ backgroundColor: p.serieColor }}
                />
                <span className="mr-4">
                  {translateCourseInstanceLeadTime(
                    p.serieId as CourseInstanceLeadTime,
                  )}
                </span>
                <span className="ml-auto">{p.data.yFormatted}</span>
              </Row>
            ))}

            <Row className="mt-2 italic">
              {leadTimeBucketSize > 1
                ? [
                    bucketStartDate.format(dateRangeFormat),
                    bucketEndDateCeiled.format(dateRangeFormat),
                  ].join(' - ')
                : bucketStartDate.format(dateRangeFormat)}
            </Row>
          </Card>
        );
      }}
    />
  );
};

export default CourseInstanceLeadTimeGraph;
