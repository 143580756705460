import { TFunction } from 'i18next';

export const formatDistance = (distance: number | null, t: TFunction) => {
  if (distance == null) {
    return t('utils.distance.unknown');
  }
  if (distance < 1000) {
    return `${Math.round(distance)} m`;
  }
  return `${(distance / 1000).toFixed(1)} km`;
};
