import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useInsights } from '../../context/insights';
import {
  CourseInstanceLeadTime,
  CourseInstanceLeadTimeXMode,
  CourseInstanceLeadTimeColor,
  CourseInstanceLeadTimeData,
  CourseInstanceLeadTimeDataResponse,
  CourseInstanceLeadTimeStatistic,
} from '../types/CourseInstanceLeadTime';
import StatisticCard from './StatisticCard';
import TimeToConfirmed from '../../resources/img/timeToConfirmed.svg';
import TimeToCompleted from '../../resources/img/timeToCompleted.svg';
import TimeConfirmedToInvoiced from '../../resources/img/timeConfirmedToInvoiced.svg';
import NotInvoiced from '../../resources/img/notInvoiced.svg';
import InvoicedInOtherMonth from '../../resources/img/invoicedInOtherMonth.svg';
import CourseInstanceLeadTimeGraph from './CourseInstanceLeadTimeGraph';
import dayjs from 'dayjs';
import CourseInstanceLeadTimeDonut from './CourseInstanceLeadTimeDonut';
import InsightsV2API from '../InsightsV2API';
import {
  translateCourseInstanceLeadTime,
  translateCourseInstanceLeadTimeStatistic,
  translateCourseInstanceLeadTimeXMode,
} from '../utils/translate';
import { CourseInstanceLeadTimeLegend } from './CourseInstanceLeadTimeLegend';
import { CourseInstanceDistribution } from './CourseInstanceDistribution';
import { updateBucketSize } from '../utils/bucketSize';
import BucketSizePicker from './BucketSizePicker';
import { getDateRangeFormat } from '../utils/axis';

const cleanUpData = (
  data: CourseInstanceLeadTimeDataResponse,
): CourseInstanceLeadTimeData => {
  const graphs = data.graphs
    .map((g) => ({
      id: g.id,
      label: translateCourseInstanceLeadTime(g.id),
      data: g.data.map((d, idx) => ({
        x: dayjs(data.from, 'YYYY-MM-DD').add(idx, 'day').toDate(),
        y: d,
      })),
      color:
        CourseInstanceLeadTimeColor[
          g.id as keyof typeof CourseInstanceLeadTimeColor
        ],
    }))
    .sort(
      (g1, g2) =>
        Object.keys(CourseInstanceLeadTime).indexOf(g1.id) -
        Object.keys(CourseInstanceLeadTime).indexOf(g2.id),
    );

  return {
    graphs,
    statistics: data.statistics,
  };
};

const CourseInstanceLeadTimeInsights: React.FC = () => {
  const { t } = useTranslation();

  const {
    market,
    subsidiaryCompanyIds,
    clientCompanyIds,
    instructorIds,
    courseIds,
    responsibleBookerIds,
    invoiceRecipientIds,
    startDate,
    endDate,
    leadTimeXMode: xMode,
    setLeadTimeXMode: setXMode,
    leadTimeBucketSize,
    setLeadTimeBucketSize,
  } = useInsights();

  const [data, setData] = useState<CourseInstanceLeadTimeData>();
  const [loadingData, setLoadingData] = useState(false);

  const [displayStart, setDisplayedStart] = useState<dayjs.Dayjs>();
  const [displayEnd, setDisplayedEnd] = useState<dayjs.Dayjs>();

  const [distributionIndex, setDistributionIndex] = useState(0);

  const fetchCourseInstanceStatusData = useCallback(async () => {
    setLoadingData(true);

    setData((prevData) => {
      if (prevData) {
        return {
          ...prevData,
          graphs: prevData.graphs.map((g) => ({
            ...g,
            data: g.data.map((d) => ({ ...d, y: 0 })),
          })),
        };
      }
      return prevData;
    });

    try {
      const { status, data } =
        await InsightsV2API.getCourseInstanceLeadTimeData(
          xMode,
          startDate,
          endDate,
          market?.id,
          subsidiaryCompanyIds,
          clientCompanyIds,
          instructorIds,
          courseIds,
          responsibleBookerIds,
          invoiceRecipientIds,
        );
      if (status === 204) {
        setData(undefined);
      } else {
        setData(cleanUpData(data));
        updateBucketSize(
          dayjs(data.from),
          dayjs(data.to),
          setLeadTimeBucketSize,
        );

        // This might not be the same as 'startDate' and 'endDate'
        setDisplayedStart(dayjs(data.from, 'YYYY-MM-DD'));
        setDisplayedEnd(dayjs(data.to, 'YYYY-MM-DD'));
      }
    } catch {
      setData(undefined);
    } finally {
      setLoadingData(false);
    }
  }, [
    clientCompanyIds,
    courseIds,
    endDate,
    instructorIds,
    invoiceRecipientIds,
    market?.id,
    responsibleBookerIds,
    setLeadTimeBucketSize,
    startDate,
    subsidiaryCompanyIds,
    xMode,
  ]);

  useEffect(() => {
    fetchCourseInstanceStatusData();
  }, [fetchCourseInstanceStatusData]);

  const getSvgForStatistic = (statistic: CourseInstanceLeadTimeStatistic) => {
    switch (statistic) {
      case CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_TO_CONFIRMED:
        return TimeToConfirmed;
      case CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_TO_COMPLETED:
        return TimeToCompleted;
      case CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_CONFIRMED_TO_INVOICED:
        return TimeConfirmedToInvoiced;
      case CourseInstanceLeadTimeStatistic.NOT_INVOICED:
        return NotInvoiced;
      case CourseInstanceLeadTimeStatistic.INVOICED_IN_OTHER_MONTH:
        return InvoicedInOtherMonth;
    }
  };

  const statistics = Object.entries(data?.statistics ?? [])
    .map((e) => {
      const statistic = e[0] as CourseInstanceLeadTimeStatistic;
      const unit =
        statistic ===
          CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_TO_CONFIRMED ||
        statistic ===
          CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_TO_COMPLETED ||
        statistic ===
          CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_CONFIRMED_TO_INVOICED
          ? t('components.CourseInstanceLeadTimeInsights.Units.day', {
              count: e[1],
            })
          : undefined;
      const average =
        statistic ===
          CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_TO_CONFIRMED ||
        statistic ===
          CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_TO_COMPLETED ||
        statistic ===
          CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_CONFIRMED_TO_INVOICED;

      return {
        id: statistic,
        title: translateCourseInstanceLeadTimeStatistic(statistic),
        value: e[1],
        svg: getSvgForStatistic(statistic),
        unit,
        average,
      };
    })
    .sort(
      (s1, s2) =>
        Object.values(CourseInstanceLeadTimeStatistic).indexOf(s1.id) -
        Object.values(CourseInstanceLeadTimeStatistic).indexOf(s2.id),
    );

  const distributionChildren = [
    <CourseInstanceLeadTimeDonut key={0} data={data} />,
  ];

  return (
    <Card>
      <div className="flex flex-col gap-8">
        <Row>
          <Col className="flex-1">
            <Row className="text-black text-xl font-semibold">
              {t('components.CourseInstanceLeadTimeInsights.title')}
            </Row>
            <Row className="w-full text-grayMedium items-center gap-1">
              <span className="font-thin">
                {t('views.InsightsV2.interval')}
              </span>

              <span className="fond-medium">
                {t('views.InsightsV2.dateRange', {
                  start: startDate.format(
                    getDateRangeFormat(startDate, endDate),
                  ),
                  end: endDate.format(getDateRangeFormat(startDate, endDate)),
                })}
              </span>

              <span className="font-thin">{t('views.InsightsV2.with')}</span>

              <Select
                value={xMode}
                onChange={setXMode}
                className="mx-1"
                popupMatchSelectWidth={false}>
                {Object.values(CourseInstanceLeadTimeXMode).map((xMode) => (
                  <Select.Option key={xMode} value={xMode}>
                    {translateCourseInstanceLeadTimeXMode(xMode).toLowerCase()}
                  </Select.Option>
                ))}
              </Select>

              <Divider type="vertical" className="h-[20px] mx-4" />

              <BucketSizePicker
                bucketSize={leadTimeBucketSize}
                setBucketSize={setLeadTimeBucketSize}
              />
            </Row>
          </Col>

          <Col>
            <Button disabled>{t('views.InsightsV2.export')}</Button>
          </Col>
        </Row>

        <Row>{data && <CourseInstanceLeadTimeLegend data={data} />}</Row>

        <Row gutter={32}>
          <Col className="w-[70%] h-[400px]">
            <CourseInstanceLeadTimeGraph
              data={data}
              displayedStart={displayStart}
              displayedEnd={displayEnd}
            />
          </Col>

          {data && (
            <Col className="w-[30%] h-[400px]">
              <CourseInstanceDistribution
                isStart={distributionIndex === 0}
                isEnd={distributionIndex === distributionChildren.length - 1}
                previous={() => {
                  if (distributionIndex > 0) {
                    setDistributionIndex(distributionIndex - 1);
                  }
                }}
                next={() => {
                  if (distributionIndex < distributionChildren.length - 1) {
                    setDistributionIndex(distributionIndex + 1);
                  }
                }}>
                {distributionChildren[distributionIndex]}
              </CourseInstanceDistribution>
            </Col>
          )}
        </Row>

        <Row gutter={16} className="items-stretch">
          {statistics.map((stat, idx) => (
            <Col key={idx} className="flex-1">
              <StatisticCard
                title={stat.title}
                value={stat.value}
                svg={stat.svg}
                unit={stat.unit}
                average={stat.average}
                loading={loadingData}
              />
            </Col>
          ))}
        </Row>
      </div>
    </Card>
  );
};

export default CourseInstanceLeadTimeInsights;
