import React from 'react';
import { CarFilled, ClockCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { formatDistance } from '../../utils/distance';
import Mileage from '../types/Mileage';
import { formatTravelTime } from '../../utils/date';

type DistanceBadgeProps = {
  distance: number;
  mileage?: Mileage;
};

const DistanceBadge: React.FC<DistanceBadgeProps> = ({ distance, mileage }) => {
  const { t } = useTranslation();

  return (
    <div className="flex">
      {mileage && mileage.distanceInMeters !== 0 ? (
        <div className="flex flex-col px-2 text-xs font-semibold rounded-full bg-green-100 text-green-800 shrink">
          <div>
            <CarFilled className="pr-1" />
            {formatDistance(mileage.distanceInMeters, t)}
          </div>
          <div>
            <ClockCircleOutlined className="pr-1" />
            {formatTravelTime(mileage.durationInSeconds)}
          </div>
        </div>
      ) : (
        <div className="px-2 text-xs font-semibold rounded-full bg-yellow-100 text-yellow-800">
          {formatDistance(distance, t)}
        </div>
      )}
    </div>
  );
};

export default DistanceBadge;
