import { Route, Routes, Navigate } from 'react-router-dom';
import { ConfigProvider, Layout, Spin } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  CategoryScale,
  LineController,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  ArcElement,
  PieController,
} from 'chart.js';

import UserAPI from './user/UserAPI';
import { changeLanguage, getAntdLocale } from './utils/language';
import UserDetails from './user/views/UserDetails';
import Users from './user/views/Users';
import { useToken } from './auth/token';
import { useUser } from './user/userContext';
import { useLanguage } from './context/language';
import Navbar from './components/Navbar';
import SubsidiaryCompanies from './subsidiaryCompany/view/SubsidiaryCompanies';
import Markets from './market/view/Markets';
import Courses from './course/views/Courses';
import Login from './auth/views/Login';
import BookingOrders from './bookingOrders/views/BookingOrders';
import ResetPassword from './auth/views/ResetPassword';
import EditBookingOrder from './bookingOrders/views/BookingOrderDetails';
import CourseInstances from './courseInstance/view/CourseInstances';
import LimeRedirect from './views/LimeRedirect';
import CourseInstanceDetails from './courseInstance/view/CourseInstanceDetails';
import BookingOrderPreview from './bookingOrders/views/BookingOrderPreview';
import Dashboard from './dashboard/views/Dashboard';
import './i18n/config';
import Insights from './insights/views/Insights';
import InsightsV2 from './insightsV2/views/InsightsV2';
import InsightsContextProvider from './context/insights';
import SyncCalendarModal from './user/components/SyncCalendarModal';
import SubsidiaryCompanyModal from './subsidiaryCompany/components/SubsidiaryCompanyModal';
import CourseInstancePreviewModal from './user/components/CourseInstancePreviewModal';
import TimeSlotModal from './user/components/TimeSlotModal';
import CourseModal from './course/components/CourseModal';
import { userHasPermission, userHasPermissions } from './user/userUtils';
import { Permission } from './user/types/Permission';
import colors from './theme/colors';
import NewBookingOrder from './bookingOrders/views/NewBookingOrder';
import MarketModal from './market/components/MarketModal';
import InvoiceRecipients from './invoiceRecipient/views/InvoiceRecipients';
import EditInvoiceRecipientModal from './bookingOrders/components/EditInvoiceRecipientModal';
import CertificateLanguages from './certificateLanguage/view/CertificateLanguages';
import CertificateLanguageModal from './certificateLanguage/components/CertificateLanguageModal';
import CertificateImages from './certificateImage/view/CertificateImages';
import CertificateImageModal from './certificateImage/components/CertificateImageModal';
import SubsidiaryCompanyGoals from './subsidiaryCompanyGoal/views/SubsidiaryCompanyGoals';
import EditSubsidiaryCompanyGoalModal from './subsidiaryCompanyGoal/components/EditSubsidiaryCompanyGoalModal';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CourseInstanceRedirect from './courseInstance/view/CourseInstanceRedirect';

ChartJS.register(
  CategoryScale,
  LineController,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  PieController,
  Tooltip,
);

const queryClient = new QueryClient();

const App: React.FC = () => {
  const [token] = useToken();
  const [currentUser, setCurrentUser] = useUser();
  const [language, setLanguage] = useLanguage();

  const fetchUser = useCallback(() => {
    UserAPI.getProfile()
      .then(({ data }) => setCurrentUser(data))
      .catch(() => {});
  }, [setCurrentUser]);

  useEffect(() => {
    if (token) {
      fetchUser();
    }
  }, [fetchUser, token]);

  useEffect(() => {
    if (currentUser) {
      changeLanguage(currentUser.preferredLanguage, setLanguage);
    }
  }, [currentUser, setLanguage]);

  const nestedUserRoutes = useMemo(
    () => (
      <>
        <Route path={'synka-kalender'} element={<SyncCalendarModal />} />
        <Route path="tidslucka/:timeSlotId" element={<TimeSlotModal />} />
        <Route
          path="kurstillfalle/:courseInstanceId"
          element={<CourseInstancePreviewModal />}
        />
      </>
    ),
    [],
  );

  return (
    <ConfigProvider
      locale={getAntdLocale(language?.code)}
      theme={{
        token: {
          colorPrimary: colors.accent[700],
          colorError: colors.red[700],
          controlItemBgHover: colors.gray[200],
          colorText: 'rgba(26, 32, 44, 0.85)',
          colorTextSecondary: 'rgba(26, 32, 44, 0.65)',
          colorLink: colors.primary[800],
        },
      }}>
      <QueryClientProvider client={queryClient}>
        <Layout className="min-h-screen bg-transparent bg-gray-200">
          {token != null && currentUser != null ? (
            <>
              <Navbar />

              <Layout.Content className="container m-auto p-8">
                <Routes>
                  {userHasPermission(
                    currentUser,
                    Permission.DASHBOARD_READ,
                  ) && (
                    <Route>
                      <Route path="/start" element={<Dashboard />} />
                    </Route>
                  )}
                  {userHasPermission(
                    currentUser,
                    Permission.CERTIFICATE_LANGUAGE_UPDATE,
                  ) && (
                    <Route>
                      <Route
                        path="/certificateLanguages"
                        element={<CertificateLanguages />}>
                        <Route
                          path=":certificateLanguageId"
                          element={<CertificateLanguageModal />}
                        />
                      </Route>
                    </Route>
                  )}
                  {userHasPermission(
                    currentUser,
                    Permission.CERTIFICATE_IMAGE_UPDATE,
                  ) && (
                    <Route>
                      <Route
                        path="/certificateImages"
                        element={<CertificateImages />}>
                        <Route
                          path=":certificateImageId"
                          element={<CertificateImageModal />}
                        />
                      </Route>
                    </Route>
                  )}
                  {userHasPermission(currentUser, Permission.INSIGHTS_READ) && (
                    <Route path="/insikter" element={<Insights />} />
                  )}
                  {userHasPermission(currentUser, Permission.INSIGHTS_READ) && (
                    <Route
                      path="/insikter-v2"
                      element={
                        <InsightsContextProvider>
                          <InsightsV2 />
                        </InsightsContextProvider>
                      }
                    />
                  )}
                  {userHasPermission(
                    currentUser,
                    Permission.SUBSIDIARY_COMPANY_GOAL_READ_LIST,
                  ) && (
                    <Route>
                      <Route
                        path="/salesTargets"
                        element={<SubsidiaryCompanyGoals />}>
                        {userHasPermission(
                          currentUser,
                          Permission.SUBSIDIARY_COMPANY_GOAL_UPDATE,
                        ) && (
                          <Route
                            path=":goalId"
                            element={<EditSubsidiaryCompanyGoalModal />}
                          />
                        )}
                      </Route>
                    </Route>
                  )}
                  {userHasPermission(
                    currentUser,
                    Permission.USER_READ_GLOBAL,
                  ) ? (
                    <Route path="/anvandare/:userId" element={<UserDetails />}>
                      {nestedUserRoutes}
                    </Route>
                  ) : (
                    <Route
                      path={`/anvandare/${currentUser.id}`}
                      element={<UserDetails />}>
                      {nestedUserRoutes}
                    </Route>
                  )}
                  {userHasPermission(
                    currentUser,
                    Permission.COURSE_INSTANCE_READ,
                  ) && (
                    <Route
                      path="/kurstillfallen/:courseInstanceId"
                      element={<CourseInstanceDetails />}
                    />
                  )}
                  {userHasPermission(
                    currentUser,
                    Permission.COURSE_INSTANCE_READ_LIST,
                  ) && (
                    <Route
                      path="/kurstillfallen"
                      element={<CourseInstances />}
                    />
                  )}
                  {userHasPermission(
                    currentUser,
                    Permission.COURSE_READ_LIST,
                  ) && (
                    <Route>
                      <Route path="/kurser" element={<Courses />}>
                        <Route path=":courseId" element={<CourseModal />} />
                      </Route>
                    </Route>
                  )}
                  {userHasPermission(
                    currentUser,
                    Permission.INVOICE_RECIPIENT_READ_LIST,
                  ) && (
                    <Route>
                      <Route
                        path="fakturamottagare"
                        element={<InvoiceRecipients />}>
                        {userHasPermission(
                          currentUser,
                          Permission.INVOICE_RECIPIENT_UPDATE,
                        ) && (
                          <Route
                            path=":invoiceRecipientId"
                            element={
                              <EditInvoiceRecipientModal
                                visible
                                setShowEditInvoiceRecipientModal={() => {}}
                                showConnectedBookingOrders
                              />
                            }
                          />
                        )}
                      </Route>
                    </Route>
                  )}
                  {userHasPermission(
                    currentUser,
                    Permission.BOOKING_ORDER_READ_LIST,
                  ) && (
                    <Route>
                      <Route
                        path="/bestallningar"
                        element={<BookingOrders />}
                      />
                      <Route
                        path="/bestallningar/lime-id/:limeId/ansvarigt-foretag-id/:subsidiaryId/"
                        element={<LimeRedirect />}
                      />
                    </Route>
                  )}
                  {userHasPermission(
                    currentUser,
                    Permission.BOOKING_ORDER_UPDATE,
                  ) ? (
                    <Route
                      path="/bestallningar/:bookingOrderId"
                      element={<EditBookingOrder />}
                    />
                  ) : (
                    userHasPermission(
                      currentUser,
                      Permission.BOOKING_ORDER_READ,
                    ) && (
                      <Route
                        path="/bestallningar/:bookingOrderId"
                        element={<BookingOrderPreview />}
                      />
                    )
                  )}
                  {userHasPermission(
                    currentUser,
                    // We can use this permission since this redirects to a booking order
                    Permission.BOOKING_ORDER_READ,
                  ) && (
                    <Route
                      path="/kurstillfallen/:courseInstanceId/"
                      element={<CourseInstanceRedirect />}
                    />
                  )}
                  {userHasPermission(
                    currentUser,
                    Permission.MARKET_READ_LIST,
                  ) && (
                    <Route>
                      <Route path="/markets" element={<Markets />}>
                        <Route path=":marketId" element={<MarketModal />} />
                      </Route>
                    </Route>
                  )}
                  {userHasPermission(
                    currentUser,
                    Permission.BOOKING_ORDER_CREATE,
                  ) && (
                    <Route
                      path="/ny-bestallning"
                      element={<NewBookingOrder />}
                    />
                  )}
                  {userHasPermission(
                    currentUser,
                    Permission.SUBSIDIARY_COMPANY_READ_LIST,
                  ) && (
                    <Route>
                      <Route path="/foretag" element={<SubsidiaryCompanies />}>
                        <Route
                          path=":subsidiaryCompanyId"
                          element={<SubsidiaryCompanyModal />}
                        />
                      </Route>
                    </Route>
                  )}
                  {userHasPermissions(
                    currentUser,
                    Permission.USER_READ_LIST,
                    Permission.USER_READ_GLOBAL,
                  ) && <Route path="/anvandare" element={<Users />} />}
                  <Route
                    path="*"
                    element={
                      userHasPermission(
                        currentUser,
                        Permission.DASHBOARD_READ,
                      ) ? (
                        <Navigate to="/start" />
                      ) : userHasPermission(
                          currentUser,
                          Permission.BOOKING_ORDER_READ,
                        ) ? (
                        <Navigate to="/bestallningar" />
                      ) : (
                        <Navigate to="/kurstillfallen" />
                      )
                    }
                  />
                </Routes>
              </Layout.Content>
            </>
          ) : token === null ? (
            <Routes>
              <Route path="/nytt-losenord" element={<ResetPassword />} />
              <Route path="/logga-in" element={<Login />} />
              <Route path="*" element={<Navigate to="/logga-in" />} />
            </Routes>
          ) : (
            <Layout.Content className="flex justify-center items-center">
              <Spin size="large" />
            </Layout.Content>
          )}
        </Layout>
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default App;
