import { CaretUpFilled, FilterFilled, PlusOutlined } from '@ant-design/icons';
import { Badge, Button, Tabs, Tooltip } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ContentCard from '../../components/ContentCard';
import CreateUserModal from '../components/CreateUserModal';
import InstructorMap from '../components/InstructorMap';
import UserFilter from '../components/UserFilter';
import UserResults from '../components/UserResults';
import { Permission } from '../types/Permission';
import User from '../types/User';
import UserAPI, { UserFilterParams } from '../UserAPI';
import { useUser } from '../userContext';
import { userHasPermission } from '../userUtils';

const Users: React.FC = () => {
  const location = useLocation();
  const [currentUser] = useUser();
  const [users, setUsers] = useState<User[]>();
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [isFilterVisible, setFilterVisible] = useState<boolean>(false);
  const [isFilterPristine, setFilterPristine] = useState<boolean>(true);

  const { t } = useTranslation();

  const fetchUsers = useCallback(async (_filter?: UserFilterParams) => {
    setLoadingUsers(false);
    try {
      const { data } = await UserAPI.getUsers(_filter);
      setUsers(data);
    } finally {
      setLoadingUsers(false);
    }
  }, []);

  const onFilterChange = useCallback(
    (filter: UserFilterParams, pristine: boolean) => {
      setFilterPristine(pristine);
      fetchUsers(filter);
      if (!pristine) {
        setFilterVisible(true);
      }
    },
    [fetchUsers],
  );

  const getLink = useCallback(
    (user: User) => {
      return `${location.pathname}/${user.id}`;
    },
    [location.pathname],
  );

  const getInitialCenter = useCallback(() => {
    const lat = currentUser?.market.mapCenterLatitude;
    const lng = currentUser?.market.mapCenterLongitude;
    return lat && lng ? { lat, lng } : undefined;
  }, [
    currentUser?.market.mapCenterLatitude,
    currentUser?.market.mapCenterLongitude,
  ]);

  const tabItems = useMemo(
    () => [
      {
        key: 'list',
        label: t('common.list'),
        children: (
          <UserResults users={users} loading={loadingUsers} getLink={getLink} />
        ),
      },
      {
        key: 'map',
        label: t('views.Users.instructorMap'),
        children: <InstructorMap initialCenter={getInitialCenter()} />,
      },
    ],
    [getInitialCenter, getLink, loadingUsers, t, users],
  );

  const onCreateUser = useCallback(() => {
    setShowUserModal(false);
    fetchUsers();
  }, [fetchUsers]);

  const hideUserModal = useCallback(() => {
    setShowUserModal(false);
  }, []);

  const presentUserModal = useCallback(() => {
    setShowUserModal(true);
  }, []);

  return (
    <>
      <div className="flex justify-between items-center">
        <h2>{t('common.users')}</h2>
        <div>
          {isFilterVisible ? (
            <Badge dot={!isFilterPristine}>
              <Tooltip placement="top" title={t('common.filter')}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<CaretUpFilled />}
                  onClick={hideUserModal}
                />
              </Tooltip>
            </Badge>
          ) : (
            <Badge dot={!isFilterPristine}>
              <Tooltip placement="top" title={t('common.filter')}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<FilterFilled />}
                  onClick={presentUserModal}
                />
              </Tooltip>
            </Badge>
          )}
          {userHasPermission(currentUser, Permission.USER_CREATE) && (
            <Button
              type="primary"
              className="text-sm font-medium ml-2"
              icon={<PlusOutlined />}
              onClick={presentUserModal}>
              {t('views.Users.newUser')}
            </Button>
          )}
        </div>
      </div>
      <UserFilter visible={isFilterVisible} onChange={onFilterChange} />
      <ContentCard>
        <Tabs defaultActiveKey="list" size="large" items={tabItems} />
      </ContentCard>
      {showUserModal &&
        userHasPermission(currentUser, Permission.USER_CREATE) && (
          <CreateUserModal
            visible={showUserModal}
            onCreateUser={onCreateUser}
            onCancel={hideUserModal}
          />
        )}
    </>
  );
};

export default Users;
